
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  computed: {},
  components: {}
})
export default class InstanceHeader extends Vue {
  @Prop({default: false}) value!: boolean;

  handleChange (): void{
    const value = !this.value;
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
