
import { Component, Vue } from 'vue-property-decorator';
import NewGiveawayFormCard from '../components/NewGiveawayFormCard.vue';
import CheckMark from '@/components/CheckMark.vue';
import { isURL } from 'class-validator';
import { GetCustomerCartResponse } from 'tayeh.js/dist/customer/types'
import { formatRelative, addDays } from 'date-fns-jalali';

@Component({
  components: {
    NewGiveawayFormCard,
    CheckMark
  },
})
export default class LandingPage extends Vue {
    private insta_img = require('@/assets/images/instagram.png');
    private trophy_img = require('@/assets/images/trophy.png');
    private heart_img = require('@/assets/images/heart.png');
    private calendar_img = require('@/assets/images/calendar.png');
    private gift_img = require('@/assets/images/gift.png');
    private coins_img = require('@/assets/images/coins.png');

    winner_no = ["اول", "دوم", "سوم", "چهارم"]
    private loading1 = false;
    private loading2 = false;
    private loading3 = false;
    private loading4 = false;
    private loading5 = false;
    private loading = false;
    private link_checked = false;
    private cart_item_changed = true;

    private cart: GetCustomerCartResponse|null = null;

    private discount = '';

    private giveaway = {
        link: '',
        winners: 1,
        days_remaining: 3,
        hours_remaining: 0,
        min_tags_count: 0,
        prizes: [''] as string[],
        should_follow: false,
        should_like: true,
        should_comment: false,
    }

    
    public get approx_date() : string {
        const milis = new Date().getTime() + (this.giveaway.days_remaining*24 + this.giveaway.hours_remaining)*60*60*1000;
        return formatRelative(new Date(milis), new Date())
        // return formatRelative(new Date(), new Date(milis));
    }
    

    // private giveaway_type = {
    //     followers_only: false,
    //     liked_only: true,
    //     comments_only: false,
    // }

    extract_media (url: string) {
        const pattern = /^(?:https?:\/\/)?(?:www\.)?instagram\.com?\/p\/([\d\w\-_]+)/i;
        const checked_regex = pattern.exec(url);
        return checked_regex?checked_regex[1]:null;
    }

    checkLink () {
        if (this.link_checked) return;
        const temp_url = this.giveaway.link?.split('?', 1)[0];
        const parts = temp_url.split('/p/');        
        if (parts.length!==2) return false;
        const url = `https://instagram.com/p/${parts[1]}`;
        this.loading1 = true;
        if (!isURL(url) || !this.extract_media(url)) {
            this.$toast.error('لطفا آدرس پست را مستقیم از اینستاگرام کپی کنید.', {bodyClassName: 'toastification'})
            this.link_checked = false;
            this.loading1 = false;
            return false;
        }
        this.giveaway.link = url;
        this.link_checked = true
        this.loading1 = false;
        return true;
    }

    winnersChanged () {
        this.giveaway.prizes = new Array(this.giveaway.winners);
        for (let i = 0; i < this.giveaway.prizes.length; i++) {
            this.giveaway.prizes[i] = '';
        }
        this.cartItemChanged()
    }

    cartItemChanged() {
        this.cart_item_changed = true;
    }

    async resetCart() {
        if (!this.link_checked) return;
        const milis = (this.giveaway.days_remaining*24 + this.giveaway.hours_remaining)*60*60*1000;
        if (milis<5.9*60*60*1000) {
            Vue.$toast.error('حداقل مهلت مجاز 6 ساعت می‌باشد.', {bodyClassName: 'toastification'})
            return;
        }
        this.$tayeh.set_token(this.$store.getters.CUSTOMER_TOKEN)
        this.loading2 = true;
        try {
            await this.setCartItem(true,
                process.env.VUE_APP_GIVEAWAY, 1)
            await this.setCartItem(true,
                process.env.VUE_APP_PER_WINNER);
        } catch (err) {
            Vue.$toast.error('به دلیل تکمیل ظرفیت امکان ایجاد قرعه کشی جدید محدود شده است، لطفا کمی دیرتر دوباره تلاش کنید.', {bodyClassName: 'toastification'})
            return;
        }
        this.loading2 = false;
        this.loading3 = true;
        try {
            await this.setCartItem(this.giveaway.should_follow, process.env.VUE_APP_FOLLOWER_FILTER)
            await this.setCartItem(this.giveaway.should_like, process.env.VUE_APP_LIKED_FILTER)
            await this.setCartItem(this.giveaway.should_comment, process.env.VUE_APP_COMMENT_FILTER);
            await this.setCartItem(this.giveaway.min_tags_count>0, process.env.VUE_APP_TAGGED_FILTER, this.giveaway.min_tags_count);
        } catch (err) {
            Vue.$toast.error('لطفا قوانین قرعه کشی را ویرایش کنید، یا کمی دیرتر دوباره تلاش کنید.', {bodyClassName: 'toastification'})
            return;
        }
        this.loading3 = false;
        this.loading4 = true;
        try {
            await this.setCartItem(true,
            process.env.VUE_APP_PER_DAY, this.giveaway.days_remaining>3?this.giveaway.days_remaining:3);
        } catch (err) {
            Vue.$toast.error('لطفا مهلت قرعه کشی را کم کنید، یا کمی صبر کنید و دوباره امتحان کنید.', {bodyClassName: 'toastification'})
            return;
        }
        this.loading4 = false;
        this.loading5 = true;
        try {
            const res = await this.$tayeh.getCustomerCart({});
            this.cart = res.data;
        } catch (err) {
            Vue.$toast.error('لطفا دوباره تلاش کنید.', {bodyClassName: 'toastification'})
            return;
        }
        this.loading5 = false;
        this.cart_item_changed = false;
    }

    setCartItem (filter: boolean, filter_id: string, count = 1): Promise<any>|void {
        if (filter) {
            return this.$tayeh.addProductToCart({
                product_id: filter_id,
                choices: [],
                count
            })
        } else if (this.cart) {
            const i = this.cart.items.find(item => item.id==filter_id)
            if (!i) return;
            return this.$tayeh.deleteCartItem(i.cart_item_id);
        }
    }

   async confirm(): Promise<void> {
       this.giveaway.prizes.map(p => p = p?.trim()||'');
       const prizes = this.giveaway.prizes.map((p) => p&&p.length>2)
       if (prizes.length==0 || !prizes.reduce((sum, p) => sum&&p)) {
           Vue.$toast.error('لطفا جایزه ها را مشخص کنید.', {bodyClassName: 'toastification'})
           return;
       }
       const milis = (this.giveaway.days_remaining*24 + this.giveaway.hours_remaining)*60*60*1000;
        if (milis<5.9*60*60*1000) {
            Vue.$toast.error('حداقل مهلت مجاز 6 ساعت می‌باشد.', {bodyClassName: 'toastification'})
            return;
        }
       try {
           await this.$tayeh.setCartDelivery({
           title: '',
           location: {countryId: 103, stateId: 3945, cityId: 135060, verbal: ''},
           position: {},
           receiver_name: 'llc',
       }, null,
    //    'manual' as any
       'tayeh' as any
       );

       const media_id = this.extract_media(this.giveaway.link);
       if (typeof this.cart?.id == 'undefined') {
           Vue.$toast.error('لطفا دوباره تلاش کنید.', {bodyClassName: 'toastification'})
           return;
       }
       if (typeof media_id !== 'string') {
           Vue.$toast.error('لطفا دوباره تلاش کنید.', {bodyClassName: 'toastification'})
           return;
       }
       const res = await this.$tayeh.getCartPay(this.discount);
       if (res.data.err || typeof res.data.payment_url!=='string') {
           Vue.$toast.error(res.data.err||'لطفا دوباره تلاش کنید.', {bodyClassName: 'toastification'})
           console.log(res.data.err);
           return
       }
       await this.$api.createNewGiveaway({
           invoice_id: this.cart?.id,
           prize: this.giveaway.prizes.join('\n'),
           media_id: media_id,
           description: '',
           chances: this.giveaway.winners,
           days_count: this.giveaway.days_remaining*86400 + this.giveaway.hours_remaining*3600,
           min_tags_count: this.giveaway.should_comment?this.giveaway.min_tags_count:0,
           should_follow: this.giveaway.should_follow,
           should_like: this.giveaway.should_like,
           should_comment: this.giveaway.should_comment,
       })
       window.location.href = res.data.payment_url;
       } catch (err) {
           Vue.$toast.error('لطفا دوباره تلاش کنید', {bodyClassName: 'toastification'})
           console.log(err);
       }
   }

    async initCart () {
        this.$tayeh.set_token(this.$store.getters.CUSTOMER_TOKEN)
        this.loading5 = true;
        this.$tayeh.deleteCartItem
        try {
            const res = await this.$tayeh.getCustomerCart({});
            this.cart = res.data;
            await this.setCartItem(false,
            process.env.VUE_APP_FOLLOWER_FILTER)
            await this.setCartItem(false,
                process.env.VUE_APP_LIKED_FILTER)
            await this.setCartItem(false,
                process.env.VUE_APP_TAGGED_FILTER, 0);
        } catch (err) {
            console.log(err);
            return;
        }
        this.cart = null;
        this.loading5 = false;
    }

    mounted () {
        this.initCart();
    }
}
